import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ApiService } from '../Services/apiservices';

let apiServices = new ApiService();
function Reservation() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Email , setEmail] = useState('')
const [successMessage, setSuccessmessage] = useState('')
const [errorMessage, setErrorMessage] = useState('')
const [sliderData, setSliderData] = useState('')
const [sliderImg, setSliderImg] = useState('')
const didMountRef = useRef(true);
useEffect(()=>{
  if(didMountRef.current){
    getSliderData()
  didMountRef.current = false;
  }
  })
useEffect(() => {
  const visitedBefore = localStorage.getItem('visitedNewsletter');
  if (!visitedBefore) {
    handleShow(); // Open the modal
    localStorage.setItem('visitedNewsletter', 'true');
  }
}, []);

const getSliderData = () =>{
  apiServices.sliderDataGetRequest().then(res=>{ 
    if(res.data.status == "success"){
      setSliderData(res.data.homeTopSliderData);
      setSliderImg(res.data.slider_img_path);
}})

}



function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}
  const submitmessage = (e) => {
    e.preventDefault()
    //console.log("Email",Email);
    if (Email === "") {
        document.getElementById("newsletter_email").style.border = "1px solid red"
        setEmail("")
        return false
        
    }
    if (!isValidEmail(Email)) {
      document.getElementById("newsletter_email").style.border = "1px solid red"
      setErrorMessage('Email is invalid');
      return false
    }    
    setErrorMessage(''); 
        document.getElementById("newsletter_email").style.border = "1px solid #aa8453"
        const dataString = {
            "newsletter_email": Email,
        }

    apiServices.newsletterPostRequest(dataString).then(res => { 
                if (res.data.status === "success") {
                    setSuccessmessage(res.data.message)
                    setEmail("")
                }
                else{
                    setErrorMessage(res.data.message)
                    setEmail("")
                }

                setTimeout(() => {
                  document.getElementById("btn_timeout").click()
                  window.location.reload();
                }, 2000);
            });
    }
    const onNavigate=()=>{
      window.location.href=" https://www.chokhidhani.ae/special-offers"
    }

  return (
    <>

{sliderData ? (
      <Modal show={show} onHide={(e)=>handleClose()} centered >
          <img src={sliderData.slider_image ? sliderImg + sliderData.slider_image :"/assets/img/defaultimage.png"} style={{width: "100%"}} onClick={(e)=>onNavigate()}/>
        <span><button type="button" className="btn-close" onClick={(e)=>handleClose()}></button></span>
      </Modal>
        ) : null}

 
    </>
  );
}

export default Reservation;
